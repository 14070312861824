<template>
  <div v-if="ready">
    <v-container>
        <v-row>
            <v-col>
                <v-text-field v-model="newId" label="Question ID"></v-text-field>
                <v-switch v-model="question.display" :true-value="true" :false_value="false" label="Displayed"/>
                <v-switch v-model="question.required" :true-value="true" :false_value="false" label="Required"/>
                Display Logic
                <LogicSelector v-model="question.displayLogic" :logics="logics"/>

                Validation Logic
                <LogicSelector v-model="question.validationLogic" :logics="logics"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-for="language in languages" :key="`question-id-${question.id}-${language.value}`">
                {{ language.value }}
                <v-text-field v-model="question.header.text[language.value]" label="Header" />
                <v-text-field v-model="question.body.text[language.value]" label="Body" />
                <v-text-field v-model="question.subtext.text[language.value]" label="Subtext" />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-btn small text @click="controller.group.add">
                    New Group<v-icon>mdi-plus</v-icon>
                </v-btn>

                <v-tabs
                    fixed-tabs
                    dark
                    v-model="tab"
                >

                    <v-tab>
                        Content
                    </v-tab>

                    <v-tab>
                        Logic
                    </v-tab>

                    <v-tab-item>
                        <draggable v-model="question.groups" handle=".handle">
                            <v-container v-for="(group, index) in question.groups" :key="`question-${question.id}-group-${group.id}`">
                                <div style="width: 100%;">
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-icon class="handle">
                                                    mdi-menu    
                                                </v-icon>
                                                #{{ index+1 }} - id: {{ group.id }} [{{ group.type }}] 
                                                <v-select v-model="group.type" :items="controller.questionTypes()" label="Type"/>
                                            </v-col>
                                            <v-col style="text-align: right;">
                                                <v-btn depressed fab x-small @click="controller.group.edit(group.id)">
                                                        <v-icon>
                                                            mdi-pencil
                                                        </v-icon>
                                                    </v-btn>
                                                    <v-btn depressed fab x-small @click="controller.group.remove(group.id)">
                                                        <v-icon>
                                                            mdi-delete
                                                        </v-icon>
                                                    </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                                <v-row>
                                    <v-col>
                                        <ChoiceEditor v-model="question.groups[index].choices" :languages="languages"/>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </draggable>
                    </v-tab-item>

                    <v-tab-item>
                        <div v-for="group in question.groups" :key="`${group.id}-logics`">
                            <v-tabs>
                                <v-tab>
                                    requirement
                                </v-tab>
                                <v-tab>
                                    validation
                                </v-tab>
                                <v-tab>
                                    display
                                </v-tab>
                                <v-tab>
                                    disable
                                </v-tab>

                                <v-tab-item v-for="(rules, requirement) in {requirementLogic: group.requirementLogic, validationLogic: group.validationLogic, displayLogic: group.displayLogic, disableLogic: group.disableLogic}" :key="`question-${question.id}-group-${group.id}-${requirement}`">
                                    <v-container fluid>
                                        <v-row>
                                            <v-col>
                                                <LogicSelector v-model="group[requirement]" :logics="logics"/>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-tab-item>

                            </v-tabs>
                        </div>  
                    </v-tab-item>

                </v-tabs>
            </v-col>
        </v-row>

        <v-row>
            <v-col v-for="language in languages" :key="`question-id-footer-${question.id}-${language.value}`">
                <v-text-field v-model="question.footer.text[language.value]" label="Footer" />
            </v-col>
        </v-row>
    </v-container>
    
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ChoiceEditor from './ChoiceEditor'
import LogicSelector from './lib/logicSelector'

let controller = function(component){
    let self = this

    function getIndex(array, id){
        let index = -1
        for(let i=0; i<array.length; i++){
            let item = array[i]
            if(item.id == id){
                index = i
                break;
            }
        }
        return index
    }

    function template(){
        let group = {
            display: true,
            required: true,
            id: requestId(),
            text: component.languageTemplate(),
            choices: [],
            displayLogic: {
                anyTrue: [],
                allTrue: [],
                anyFalse: [],
                allFalse: []
            },
            validationLogic: {
                anyTrue: [],
                allTrue: [],
                anyFalse: [],
                allFalse: []
            },
            disableLogic: {
                anyTrue: [],
                allTrue: [],
                anyFalse: [],
                allFalse: []
            },
            requirementLogic: {
                anyTrue: [],
                allTrue: [],
                anyFalse: [],
                allFalse: []
            }
        }

        return group
    }

    function requestId(){
        let groups = component.question.groups
        let newId = 1
        for (let i=0; i<=groups.length; i++) {
            newId = i+1
            let index = getIndex(groups, newId)
            if(index<0){
                return newId
            }
        }
        return newId
    }

    this.group = {
        add(){
            console.group('add')
            let group = template()
            component.question.groups.push(group)
            console.log(group, component.question.groups)
            console.groupEnd()
        },
        remove(id){
            console.group('remove', id)
            let groups = component.question.groups
            let index = getIndex(groups, id)
            
            if(confirm(`Remove group: ${id} - ${groups[index].text['en-ca']}`)){
                groups.splice(index, 1)
            }
            console.groupEnd()
        },
        close(){
            
        }
    }

    this.questionTypes = ()=>{
        return [
            {
                text: 'radio',
                value: 'radio'
            },
            {
                text: 'checkbox',
                value: 'checkbox'
            },
            {
                text: 'textfield',
                value: 'textfield'
            },
            {
                text: 'number',
                value: 'number'
            },
            {
                text: 'number-slider',
                value: 'number-slider'
            },
            {
                text: 'textarea',
                value: 'textarea'
            },
            {
                text: 'toggle',
                value: 'toggle'
            }
        ]
    }
}

export default {
    name: "QuestionEditor",
    components: {
        draggable,
        ChoiceEditor,
        LogicSelector
    },
    props: {
        languages: {
            type: Array,
            required: true
        },
        logics: {
            type: Array,
            required: true
        }
    },
    mounted(){
        this.controller = new controller(this)
        this.newId = this.question.id
    },
    data(){
        return {
            controller: null,
            tab: null,
            newId: undefined
        }
    },
    methods: {
        languageTemplate(){
            let languages = this.languages
            let output = {}
            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                output[language.value] = null
            }
            return output
        }
    },
    computed: {
        ready(){
            return this.controller != null && this.question!=null
        },
        question(){
            return this.$attrs.value
        }
    },
    watch: {
        question: {
            deep: true,
            handler(){
                this.$emit('input',this.question)
            }
        },
        newId: {
            deep: false,
            handler: function(after, before){
                console.log({after, before})
                if(before!=undefined){
                    let newId = after
                    if(newId != this.question.id){
                        this.$emit('changeQid',{from:this.question.id, to: parseInt(newId,10)})
                    }
                }
            }
        }
    }
}
</script>

<style>

</style>