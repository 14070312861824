<template>
  <div>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-list dense>
                    <v-list-item v-for="(logic, index) in logics" :key="`logic-${logic.id}`">
                        <v-list-item-content>
                           #{{ index+1 }} | [{{ logic.id }}] - {{ logic.description }}
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn small text :disabled="!target" @click="add(model[target], logic.id)">Add</v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-col>
            <v-col>
                <v-select v-model="target" label="Add to" :items="_targetItems"></v-select>
            </v-col>
            <v-col v-for="(rules, requirement) in model" :key="`${requirement}`">
                {{requirement}}
                <v-list>
                    <v-list-item v-for="(ruleId, index) in rules" :key="`${requirement}-${ruleId}`">
                        <v-list-item-content>
                           #{{ index+1 }} | {{ logics.find((logic)=>{return logic.id==ruleId}).description }}
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn x-small fab depressed @click="remove(rules, ruleId)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
    props: {
        logics: {
            type: Array,
            required: true
        }
    },
    data(){
        return {
            target: null
        }
    },
    methods: {
        add(target, value){
            if(!target.includes(value)){
                target.push(value)
            }
        },
        remove(rules, ruleId){
            let index = rules.indexOf(ruleId)
            rules.splice(index, 1)
        }
    },
    computed: {
        model(){
            return this.$attrs.value
        },
        _targetItems(){
            let items = []
            for(let requirement in this.model){
                items.push({
                    text: requirement,
                    value: requirement
                })
            }

            return items
        }
    }
}
</script>

<style>

</style>