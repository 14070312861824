<template>
  <div v-if="ready">

    <v-container>
        Last Saved: {{ new Date(model.updated) }}
        <v-text-field v-model="model.version" label="Version"/>
        <v-row>
            <v-col v-for="language in languages" :key="`editing-language-${language.value}`">
                {{language.text}}
                <v-text-field v-model="model.title[language.value]" label="Title" />
                <v-text-field v-model="model.description[language.value]" label="Description" />
            </v-col>
        </v-row>

        <draggable v-model="model.questions" handle=".handle">
            <v-row v-for="(question, index) in model.questions" :class="[{'hidden':!question.display}]" :key="`question-row-${question.id}`">
                <div style="width: 100%;">
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-icon class="handle">
                                    mdi-menu    
                                </v-icon>
                                #{{ index+1 }} - id: {{ question.id }}
                            </v-col>
                            <v-col style="text-align: right;">
                                <v-btn depressed fab x-small @click="controller.question.edit(question.id)">
                                        <v-icon>
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                    <v-btn depressed fab x-small @click="controller.question.remove(question.id)">
                                        <v-icon>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
                
                <v-col v-for="language in languages" :key="`editing-language-${language.value}`">
                    <v-card>
                        <v-card-title>
                            {{ question.header.text[language.value] }}
                        </v-card-title>
                        <v-card-subtitle>
                            {{ question.body.text[language.value] }}
                        </v-card-subtitle>
                        <v-card-subtitle>
                            {{ question.subtext.text[language.value] }}
                        </v-card-subtitle>
                        <v-card-text>
                            <v-container fluid>
                                <v-row v-for="group in question.groups" :key="`question-${question.id}-group-${group.id}-${language.value}`">
                                    <v-col>
                                        [{{ group.type }}] -- {{ group.text[language.value] }} --
                                        <v-container fluid>
                                            <v-row v-for="choice in group.choices" :key="`question-${question.id}-group-${group.id}-choice-${choice.id}-${language.value}`">
                                                <v-col>
                                                    {{ choice.text[language.value] }}
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>        
        </draggable>
    </v-container>
    


    <v-btn style="position: fixed; bottom: 65px; right: 15px;" dark small depressed color="red" fab @click="$emit('saveQuestionSet',model)">
        <v-icon>
            mdi-floppy
        </v-icon>
    </v-btn>

    <v-btn style="position: fixed; bottom: 15px; right: 15px;" dark small depressed color="green" fab @click="controller.question.add">
        <v-icon>
            mdi-plus
        </v-icon>
    </v-btn>

    <v-dialog v-model="ui.dialog" fullscreen>
        <v-card>
            <v-card-text>
                <QuestionEditor v-model="editing" @changeQid="changeQid" :logics="model.logics" :languages="languages"/>
            </v-card-text>
            <v-card-actions>
                <v-btn style="position: fixed; bottom: 15px; left: 15px;" @click="controller.question.close">
                    Close
                </v-btn>
                <v-btn style="position: fixed; bottom: 15px; right: 15px;" @click="controller.question.save">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import QuestionEditor from './QuestionEditor.vue'

let controller = function(component){
    let self = this

    function getIndex(array, id){
        let index = -1
        for(let i=0; i<array.length; i++){
            let item = array[i]
            if(parseInt(item.id, 10) == parseInt(id, 10)){
                index = i
                break;
            }
        }
        return index
    }

    function template(){
        let question = {
            required: true,
            display: true,
            id: requestId(),
            header: {
                text: component.languageTemplate()
            },
            body: {
                text: component.languageTemplate()
            },
            subtext: {
                text: component.languageTemplate()
            },
            footer: {
                text: component.languageTemplate()
            },
            groups: [],
            displayLogic: {
                anyTrue: [],
                allTrue: [],
                anyFalse: [],
                allFalse: [],
                // noneTrue: []
            },
            validationLogic: {
                anyTrue: [],
                allTrue: [],
                anyFalse: [],
                allFalse: [],
                // noneTrue: []
            },
            disableLogic: {
                anyTrue: [],
                allTrue: [],
                anyFalse: [],
                allFalse: [],
                // noneTrue: []
            }
        }

        return question
    }

    function requestId(){
        let questions = component.model.questions
        let newId = 1
        for (let i=0; i<=questions.length; i++) {
            newId = i+1
            let index = getIndex(questions, newId)
            if(index<0){
                return newId
            }
        }
        return newId
    }

    this.question = {
        getIndex,
        requestId,
        add(){
            console.group('add')
            let question = template()
            component.model.questions.push(question)
            console.log(question, component.model.questions)
            console.groupEnd()
            self.question.edit(question.id)
        },
        remove(id){
            let questions = component.model.questions
            let index = getIndex(questions, id)
            if(confirm(`Remove question: ${id} - ${questions[index].body.text['en-ca']}`)){
                questions.splice(index, 1)
            }
        },
        edit(id){
            component.editing = null

            let questions = component.model.questions
            let index = getIndex(questions, id)
            let question = questions[index]

            if(question){
                let clone = JSON.stringify(question)
                clone = JSON.parse(clone)
                
                let params = component.$route.params
                params.questionId = id.toString()
                component.$router.push({name:'editQuestion',params})
                
                setTimeout(() => {
                    component.editing = clone
                    component.ui.dialog = true
                }, 200);
            }else{
                self.question.close()
            }
        },
        close(){
            component.ui.dialog = false
            component.$router.push({name:'editQuestionSet', params:{questionSetId:component.$route.params.questionSetId}})
        },
        save(){
            let question = component.editing
            let index = getIndex(component.model.questions, question.id)
            component.$emit('saveQuestion',{
                question,
                index
            })
            self.question.close()
        }
    }

    
    
}

export default {
    name: "QuestionSetEditor",
    created(){
        let self = this
        for(let key in this.model){
            if(this.model[key]===null){
                this.model[key] = this.languageTemplate()
            }
        }

        self.controller = new controller(self)
        let questionId = parseInt(self.$route.params.questionId, 10)
    
        self.ready = true
        if(questionId){
            self.controller.question.edit(questionId)
        }

    },
    components: {
        draggable,
        QuestionEditor
    },
    data(){
        return {
            ready: false,
            controller: undefined,
            editing: null,
            ui: {
                dialog: false
            }
        }
    },
    methods: {
        languageTemplate(){
            let languages = this.languages
            let output = {}
            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                output[language.value] = null
            }
            return output
        },
        changeQid(data){
            this.$emit('changeQid',data)            
        }
    },
    computed: {
        model(){
            return this.$attrs.value
        },
        languages(){
            return this.$store.getters.controller.Language.languages
        }
    }
}
</script>

<style scoped>
.hidden{
    border: 1px solid purple;
}
</style>