<template>
    <div v-if="ready">        
        <draggable v-model="choices" handle=".handle">
            <v-card v-for="(choice, index) in choices" :key="`choice-${choice.id}`" style="margin-bottom: 25px;">
                <v-card-text>
                    <v-container>
                        <div style="width: 100%;">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-icon class="handle">
                                            mdi-menu    
                                        </v-icon>
                                        #{{ index+1 }} - id: {{ choice.id }} [{{ choice.type }}]
                                    </v-col>
                                    <v-col style="text-align: right;">
                                        <v-btn depressed fab x-small @click="controller.choice.remove(choice.id)">
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="choice.value" label="value"/>
                            </v-col>
                            <v-col v-for="language in languages" :key="`choice-${choice.id}-${language.value}`">
                                <v-text-field v-model="choice.text[language.value]" :label="language.value"/>
                            </v-col>
                        </v-row>  
                        <v-row>
                            <v-col>
                                <v-switch :true-value="true" :false-value="false" v-model="choice.exclusive" label="Exclusive"/>
                                <v-switch :true-value="true" :false-value="false" v-model="choice.other" label="Other"/>
                            </v-col>
                        </v-row>
                    </v-container>    
                </v-card-text>
            </v-card>
        </draggable>  
        <v-card>
            <v-card-actions>
                <v-btn small @click="controller.choice.add">
                    Choice <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>    
    </div>
  </template>
  
  <script>
  import draggable from 'vuedraggable'
  let controller = function(component){
      let self = this
  
      function getIndex(array, id){
          let index = -1
          for(let i=0; i<array.length; i++){
              let item = array[i]
              if(item.id == id){
                  index = i
                  break;
              }
          }
          return index
      }
  
      function template(){
          let output = {
              display: true,
              id: requestId(),
              text: component.languageTemplate(),
              exclusive: false,
              other: false,
              displayLogic: {
                  anyTrue: [],
                  allTrue: [],
                  noneTrue: []
              },
              validationLogic: {
                  anyTrue: [],
                  allTrue: [],
                  noneTrue: []
              },
              disableLogic: {
                  anyTrue: [],
                  allTrue: [],
                  noneTrue: []
              }
          }
  
          return output
      }
  
      function requestId(){
          let choices = component.choices
          let newId = 1
          for (let i=0; i<=choices.length; i++) {
              newId = i+1
              let index = getIndex(choices, newId)
              if(index<0){
                  return newId
              }
          }
          return newId
      }
  
      this.choice = {
          add(){
              console.group('add')
              let choice = template()
              component.choices.push(choice)
              console.log(component.choices, component.choices)
              console.groupEnd()
          },
          remove(id){
              console.group('remove', id)
              let choices = component.choices
              let index = getIndex(choices, id)
              
              if(confirm(`Remove choice: ${id} - ${choices[index].text['en-ca']}`)){
                  choices.splice(index, 1)
              }
              console.groupEnd()
          },
          close(){
              
          }
      }
  }
  
  export default {
      name: "ChoiceEditor",
      components: {
          draggable
      },
      props: {
          languages: {
              type: Array,
              required: true
          }
      },
      created(){
            let clone = JSON.stringify(this.model)
            this.choices = JSON.parse(clone)
            this.controller = new controller(this)
      },
      data(){
          return {
              controller: null,
              choices: null
  
          }
      },
      methods: {
          languageTemplate(){
              let languages = this.languages
              let output = {}
              for(let i=0; i<languages.length; i++){
                  let language = languages[i]
                  output[language.value] = null
              }
              return output
          }
      },
      computed: {
          ready(){
              return this.controller != null && this.choices!=null
          },
          model(){
              return this.$attrs.value
          }
      },
      watch: {
        choices: {
            deep: true,
            handler(){
                this.$emit('input',this.choices)
            }
        }
      }
  }
  </script>
  
  <style>
  
  </style>