<template>
  <div v-if="ready">
    <v-container>
        <draggable v-model="model.logics" handle=".handle">
            <v-row v-for="(logic, index) in model.logics" :key="`logicRow-${logic.id}`">
                <v-col>
                    <v-card>
                        <v-card-actions>
                            <v-icon class="handle">mdi-menu</v-icon>
                            #{{index+1}} - id:{{ logic.id }}
                            <v-spacer></v-spacer>
                            <v-btn depressed x-small fab @click="controller.logic.edit(logic.id)">
                                <v-icon>
                                    mdi-pencil
                                </v-icon>
                            </v-btn>
                            <v-btn depressed x-small fab @click="controller.logic.remove(logic.id)">
                                <v-icon>
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </v-card-actions>
                        
                        <v-card-title>
                            {{ logic.description }}
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>    
        </draggable>
    </v-container>


    <v-btn depressed fab small color="green" dark style="position: fixed; bottom: 15px; right: 15px;" @click="controller.logic.add">
        <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="ui.dialog" fullscreen>
        <v-card v-if="ui.dialog && editing">
            <v-card-text>
                <v-text-field v-model="editing.description" label="Description"></v-text-field>
                <v-container fluid>
                    <v-row>
                        <v-col v-for="(rules, requirement) in editing.rules" :key="`${requirement}`">
                            <v-btn small @click="controller.rule.add(rules)">
                                {{ requirement }} <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-container fluid>
                                <draggable v-model="editing.rules[requirement]" handle=".handle">
                                    <v-row v-for="(rule, index) in rules" :key="`${requirement}-${rule.id}`">
                                            <v-col>
                                                <v-card>
                                                    <v-card-actions>
                                                        <v-icon class="handle">
                                                            mdi-menu
                                                        </v-icon>
                                                        #{{ index+1 }} | [{{ rule.id }}]
                                                        <v-spacer/>
                                                        <v-btn @click="controller.rule.remove(rule.id, rules)" depressed fab x-small>
                                                            <v-icon x-small>
                                                                mdi-delete
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-card-actions>
                                                    
                                                    <v-card-text>
                                                        <v-text-field v-model="rule.description" label="Description"></v-text-field>
    
                                                        <v-container fluid>
                                                            <v-row>
                                                                <v-col>
                                                                    <template v-if="!rule.inputSource.qid">
                                                                        Select question
                                                                    </template>
                                                                    <template v-else-if="!rule.inputSource.gid">
                                                                        {{ model.questions.find((question)=>{return question.id==rule.inputSource.qid}).body.text['en-ca'] }}<br>
                                                                        Select group or use isAnswered operator
                                                                    </template>
                                                                    <template v-else>
                                                                        #{{index+1}} - [{{ rule.inputSource.qid }}] [{{ rule.inputSource.gid }}]
                                                                    </template>
                                                                    <br>
                                                                    
                                                                    <template v-if="!rule.inputSource.qid">
                                                                        <v-radio-group dense v-model="rule.inputSource.qid">
                                                                            <v-radio v-for="(question, index) in model.questions" :key="`${requirement}-${question.id}`" :value="question.id" :label="`# ${index+1 } | [${question.id}] -  ${question.body.text['en-ca']} `">
                                                                            </v-radio>                                                                    
                                                                        </v-radio-group>
                                                                    </template>

                                                                    <template v-else>
                                                                        <v-btn x-small text @click="rule.inputSource.gid=null;rule.inputSource.qid=null">Back</v-btn>
                                                                        <v-radio-group dense v-model="rule.inputSource.gid">
                                                                            <v-radio v-for="(group, index) in model.questions.find((question)=>{return question.id==rule.inputSource.qid}).groups" :key="`${requirement}-${rule.inputSource.qid}-${group.id}`" :value="group.id" :label="`# ${index+1 } | [${group.id}] -  ${group.text['en-ca']} `">
                                                                            </v-radio>                                                                    
                                                                        </v-radio-group>
                                                                    </template>

                                                                    <template v-if="rule.inputSource.gid">
                                                                        <!-- <json-viewer :value="model.questions.find((question)=>{return question.id==rule.inputSource.qid}).groups.find((group)=>{return group.id==rule.inputSource.gid}).choices"/> -->
                                                                        <v-list dense>
                                                                            <v-list-item v-for="(choice) in model.questions.find((question)=>{return question.id==rule.inputSource.qid}).groups.find((group)=>{return group.id==rule.inputSource.gid}).choices" :key="`${requirement}-${rule.inputSource.qid}-${rule.inputSource.gid}-${choice.id}`">
                                                                                <v-list-item-content>
                                                                                    - {{ choice.text['en-ca'] }}
                                                                                </v-list-item-content>
                                                                                <v-list-item-action>
                                                                                    <v-btn x-small text @click="inputValue[`${requirement}-${rule.id}`]=choice.value;$forceUpdate()">Use</v-btn>
                                                                                </v-list-item-action>
                                                                            </v-list-item>
                                                                        </v-list>
                                                                    </template>
                                                                    

                                                                </v-col>
                                                                <v-col>
                                                                    <v-select v-model="rule.operator" :items="controller.rule.getOperators()"></v-select>
                                                                </v-col>
                                                                <v-col>
                                                                    <v-text-field v-model="inputValue[`${requirement}-${rule.id}`]" @keydown.enter="controller.rule.addValue(rule, `${requirement}-${rule.id}`)" @click:append-outer="controller.rule.addValue(rule, `${requirement}-${rule.id}`)" append-outer-icon="mdi-plus"/>
                                                                    <v-list>
                                                                        <v-list-item v-for="(value, index) in rule.value" :key="`${requirement}-${rule.id}-${index}`">
                                                                            <v-list-item-content>
                                                                                #{{index+1}} | {{value}}
                                                                            </v-list-item-content>
                                                                            <v-list-item-action>
                                                                                <v-btn fab x-small depressed @click="controller.rule.removeValue(rule, index)">
                                                                                    <v-icon x-small>
                                                                                        mdi-delete
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </v-list-item-action>
    
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
    
                                                        
                                                        <!-- <json-viewer :value="rules"/> -->
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                </draggable>


                            </v-container>
                        </v-col>
                    </v-row>
                </v-container>
                <!-- <json-viewer :value="editing"/> -->
            </v-card-text>
            <v-card-actions>
                <v-btn style="position: fixed; bottom: 15px; left: 15px;" @click="controller.logic.close">
                    Close
                </v-btn>
                <v-btn style="position: fixed; bottom: 15px; right: 15px;" @click="controller.logic.save">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
let controller = function(component){
    let self = this

    function getIndex(array, id){
        let index = -1
        for(let i=0; i<array.length; i++){
            let item = array[i]
            if(item.id == id){
                index = i
                break;
            }
        }
        return index
    }

    function template(){
        let group = {
            id: requestId(component.model.logics),
            description: null,
            rules: {
                allTrue: [],
                anyTrue: [],
                anyFalse: [],
                allFalse: []
            }
        }

        return group
    }

    function ruleTemplate(rules){
        let output = {
            id: requestId(rules),
            description: null,
            operator: null,
            value: [],
            inputSource: {
                qid: null,
                gid: null
            }
        }

        return output
    }

    function requestId(array){
        let newId = 1
        for (let i=0; i<=array.length; i++) {
            newId = i+1
            let index = getIndex(array, newId)
            if(index<0){
                return newId
            }
        }
        return newId
    }

    function getRequirements(){
        return [
            {
                text: 'allTrue',
                value: 'allTrue'
            },
            {
                text: 'anyTrue',
                value: 'anyTrue'
            },
            {
                text: 'anyFalse',
                value: 'anyFalse'
            },
            {
                text: 'allFalse',
                value: 'allFalse'
            }
        ]
    }
    function getOperators(){
        let output = [
            {
                text: "isAnswered",
                value: "isAnswered"
            },
            {
                text: "=",
                value: "=="
            },
            {
                text: "!=",
                value: "!="
            },
            {
                text: ">",
                value: ">"
            },
            {
                text: "≥",
                value: ">="
            },
            {
                text: "<",
                value: "<"
            },
            {
                text: "≤",
                value: "<="
            }
        ]

        return output
    }    

    this.logic = {
        add(){
            console.group('add')
            let logic = template()
            component.model.logics.push(logic)
            console.log(logic, component.model.logics)
            self.logic.edit(logic.id)
            console.groupEnd()
        },
        remove(id){
            console.group('remove', id)
            let logics = component.model.logics
            let index = getIndex(logics, id)
            
            if(confirm(`Remove group: ${id} - ${logics[index].description}`)){
                logics.splice(index, 1)
            }
            console.groupEnd()
        },
        edit(id){
            component.editing = null

            let logics = component.model.logics
            let index = getIndex(logics, id)
            let logic = logics[index]

            if(logic){
                let clone = JSON.stringify(logic)
                clone = JSON.parse(clone)
                
                let params = component.$route.params
                params.logicId = id.toString()
                component.$router.push({name:'editLogic',params})
                
                setTimeout(() => {
                    component.editing = clone
                    component.ui.dialog = true
                }, 200);
            }else{
                self.question.close()
            }
        },
        close(){
            component.ui.dialog = false
            component.$router.push({name:'editLogicSet', params:{questionSetId:component.$route.params.questionSetId}})
        },
        save(){
            let index = component.model.logics.findIndex((logic)=>{return logic.id == component.editing.id})
            component.model.logics[index] = component.editing
            self.logic.close()
        }
    }

    this.rule = {
        add(rules){
            console.group('add')
            let logic = ruleTemplate(rules)
            console.log('rule.add',{logic, rules})
            rules.push(logic)
            console.groupEnd()
        },
        remove(id, rules){
            console.group('remove', id)
            let index = getIndex(rules, id)
            
            if(confirm(`Remove logic: ${id} - ${rules[index].description}`)){
                rules.splice(index, 1)
            }
            console.groupEnd()
        },
        addValue(rule, inputId){
            let value = component.inputValue[inputId]
            if(value && value.length>0 && !rule.value.includes(value)){
                rule.value.push(value)
                component.inputValue[inputId] = null
            }
        },
        removeValue(rule, index){
            rule.value.splice(index,1)
        },
        getOperators
    }

    this.getRequirements = getRequirements
}
export default {
    name: "LogicEditor",
    created(){
        let self = this
        self.controller = new controller(self)
        let logicId = parseInt(self.$route.params.logicId, 10)
        if(logicId){
            self.controller.logic.edit(logicId)
        }
        self.ready = true
    },
    components: {
        draggable
    },
    data(){
        return {
            ready: false,
            controller: undefined,
            editing: null,
            ui: {
                dialog: false
            },
            inputValue: {}
        }
    },
    methods: {
        languageTemplate(){
            let languages = this.languages
            let output = {}
            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                output[language.value] = null
            }
            return output
        },
        test(e){
            console.log('test',e)
        }
    },
    computed: {
        model(){
            return this.$attrs.value
        },
        questions(){
            return this.$attrs.value.questions
        },
        languages(){
            return this.$store.getters.controller.Language.languages
        }
    }
}
</script>

<style>

</style>