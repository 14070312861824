// table parameter in export METHOD should be a selector to get the table. 
var exportToExcel = {
    methods: {
      export(table) {
        let date = new Date();
        let outputFile =
          date.getDate() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getFullYear() +
          ".csv";
        // CSV
        this.exportTableToCSV(table, outputFile);
      },
      exportTableToCSV($table, filename) {
        var $headers = document.querySelector($table).querySelectorAll("thead");
        var $rows = document.querySelector($table).querySelectorAll("tbody tr");
        // console.log($headers);
        // Temporary delimiter characters unlikely to be typed by keyboard
        // This is to avoid accidentally splitting the actual contents
        var tmpColDelim = String.fromCharCode(11); // vertical tab character
        var tmpRowDelim = String.fromCharCode(0); // null character
        // actual delimiter characters for CSV format
        var colDelim = '","';
        var rowDelim = '"\r\n"';
        // Grab text from table into CSV formatted string
        var csv = '"';
        var arrayHeaders = [...$headers];
        var arrayRows = [...$rows];
        // console.log(arrayHeaders);
        csv += formatRows(arrayHeaders.map(grabRow));
        csv += rowDelim;
        csv += formatRows(arrayRows.map(grabRow)) + '"';
        // Data URI
        var csvData =
          "data:application/csv;charset=utf-8," + encodeURIComponent(csv);
  
        var links = document.createElement("a");
        document.body.appendChild(links);
        links.download = filename;
        links.href = csvData;
        links.click();
        document.body.removeChild(links);
  
        //------------------------------------------------------------
        // Helper Functions
        //------------------------------------------------------------
        // Format the output so it has the appropriate delimiters
        function formatRows(rows) {
          return rows
            .join(tmpRowDelim)
            .split(tmpRowDelim)
            .join(rowDelim)
            .split(tmpColDelim)
            .join(colDelim);
        }
        // Grab and format a row from the table
        function grabRow(row, i) {
          // console.log(row);
          // var $row = $(row);
          //for some reason $cols = $row.find('td') || $row.find('th') won't work...
          var $cols = row.querySelectorAll("td");
          if (!$cols.length) $cols = row.querySelectorAll("th");
          $cols = [...$cols];
          var $r = $cols.map(grabCol).join(tmpColDelim);
          return $r;
        }
        // Grab and format a column from the table
        function grabCol(col, j) {
          // console.log("COLUMNA:", col);
          var text = col.textContent;
          text = text.replace(new RegExp("\\n", "g"), ""); // remove line breaks;
          text = text.replace(/ +(?= )/g, " "); // remove multiple spaces;
          return text.replace('"', '""'); // escape double quotes
        }
      }
    }
  };
  
  export default exportToExcel;