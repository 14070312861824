<template>
  <div>
    <h1>Admin</h1>
    <v-btn x-small @click="getQuestionSets()">getQuestionSets</v-btn>
    <v-btn fab color="primary" v-if="QuestionSetSelection!=null" @click="copy">
      <v-icon>mdi-content-copy</v-icon>
    </v-btn>
    <v-select v-model="QuestionSetSelection" :items="_QuestionSetItems" label="Select QuestionSet"/>

    <template  v-if="editing">
      <v-tabs
        fixed-tabs
        dark
        v-model="tab"
      >
        <v-tab :disabled="tab.disabled" v-for="(tab, index) in _tabs" :key="`tab-${index}`" @click="$router.push({name:tab.target,params: $route.params})">
          {{tab.text}}
        </v-tab>

        <v-tab-item>
          <QuestionSetEditor v-model="editing" @saveQuestionSet="saveQuestionSet" @saveQuestion="saveQuestion" @changeQid="changeQid"/>
        </v-tab-item>

        <v-tab-item>
          <LogicEditor v-model="editing"/>
        </v-tab-item>

        <v-tab-item>
          <h3 align="center">Prompts</h3>
        </v-tab-item>
      </v-tabs>

      <p align="center">
        <v-btn @click="preview = JSON.stringify(editing); preview=JSON.parse(preview); ui.preview = true">
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </p>

      <v-dialog v-if="editing && preview" v-model="ui.preview" :fullscreen="true">
        <v-card>
          <v-card-actions style="position: fixed; top: 0px; left: 0px;">
            <v-spacer></v-spacer>
            <v-btn small text @click="ui.preview=false; preview=null">X</v-btn>
          </v-card-actions>


          <v-card-text>
            
            <v-container>
              <v-row>
                <v-col>
                  <QuestionSet :questionSet="preview" v-model="answers" @registerController="registerQuestionController"/>
                </v-col>
                <v-col>
                  <v-sheet style="height:800px; overflow-y: scroll;">
                    <v-list>
                      <v-list-item v-for="question in questionMap" :key="`questionControl-${question.id}`">
                        <v-list-item-content>
                          <json-viewer :value="question"/>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div>
                            <v-btn x-small text @click="question.controller.status">Status</v-btn>
                            <v-btn x-small text @click="question.controller.reset">reset</v-btn>
                            <v-btn x-small text @click="question.controller.debug">debug</v-btn>
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>                  
                  </v-sheet>
                </v-col>
                <v-col>
                  <v-sheet style="height:800px; overflow-y: scroll;">
                    <v-list>
                      <v-list-item v-for="logic in logicMap" :key="`logicControl-${logic.id}`">
                        <v-list-item-content>
                          <json-viewer :value="logic"/>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div>
                            <v-btn x-small text @click="logic.controller.debug">debug</v-btn>
                            <v-btn x-small text @click="logic.controller.process(answers)">process</v-btn>
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>



  </div>
</template>

<script>
import QuestionSetEditor from '@/components/QuestionSystem/Editor/QuestionSetEditor'
import LogicEditor from '@/components/QuestionSystem/Editor/LogicEditor'
import QuestionSet from '@/components/QuestionSystem/QuestionSet.vue'

export default {
  props: {
    Controller: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: false,
      default: 'en-ca'
    }
  },
  components: {
    QuestionSetEditor,
    LogicEditor,
    QuestionSet
  },
  async created(){
    await this.getQuestionSets()
    if(this.$route.params.questionSetId){
      this.QuestionSetSelection = parseInt(this.$route.params.questionSetId, 10)
    }
    let path = this.$route.name
    let index = this._tabs.findIndex((item)=>{return item.paths.includes(path)})

    let self = this
    
    if(index>=0){
      self.tab = index    
    }
  },
  data(){
    return {
      QuestionSets: {},
      QuestionSetSelection: null,
      editing: null,
      tab: null,
      ui: {preview: false},

      preview: null,
      answers: null,

      questionMap: null,
      logicMap: null,
      answers: {}
    }
  },
  methods: {
    copy(){
      let jsonData = this.QuestionSets[this.QuestionSetSelection]
      let selBox = document.createElement('textarea');
      selBox.setAttribute("id", "json-copy");
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = JSON.stringify(jsonData);
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      navigator.clipboard.writeText(selBox.value);
      document.body.removeChild(selBox);
    },
    async getQuestionSets(){
      try {
        let response = await this.Controller.QuestionSet.getQuestionSet()
        let records = response.data.questionSet
        this.QuestionSets = {}
        for(let i=0; i<records.length; i++){
          let record = records[i]
          this.$set(this.QuestionSets, record.id, record)
        }
      } catch (error) {
        
      }
    },
    async saveQuestionSet(data){
      console.log('saveQuestionSet',data)
      try {
        let response = await this.Controller.QuestionSet.saveQuestionSet(data)
        console.log('saveQuestionSet',response)
      } catch (error) {
        
      }
      
    },
    async saveQuestion(data){
      let index = data.index
      let question = data.question
      let questionSetId = this.QuestionSetSelection
      this.editing.questions[index] = question
      this.QuestionSets[questionSetId].questions[index] = question
    },
    registerQuestionController(data){
      console.log('registerQuestionController',data)
      this.questionMap = data.questionMap
      this.logicMap = data.logicMap
    },
    changeQid: function(data){
      console.log('Admin > changeQid',data)
      let index = this.editing.questions.findIndex((question)=>{return question.id==data.from})
      this.editing.questions[index].id = data.to
    }
  },
  computed: {
    _QuestionSetItems(){
      let language = this.language
      let items = []
      let QuestionSets = this.QuestionSets
      for(let id in QuestionSets){
        let record = QuestionSets[id]
        items.push({
          text: record.title[language],
          value: record.id
        })
      }

      return items
    },
    _tabs(){
      return [
        {
          text: "Question",
          paths: ["questionAdmin","editQuestionSet","editQuestion"],
          target: "editQuestionSet",
          disabled: false
        },
        {
          text: "Logic",
          paths: ["logicAdmin","editLogicSet","editLogic"],
          target: "editLogicSet",
          disabled: false
        },
        {
          text: "Prompt",
          paths: ["promptAdmin","editPromptSet","editPrompt"],
          target: "editPromptSet",
          disabled: true
        }
      ]
    }
  },
  watch: {
    QuestionSetSelection(){
      let self = this
      this.editing = null
      let clone = JSON.stringify(this.QuestionSets[this.QuestionSetSelection])
      if(this.$route.params.questionSetId!=this.QuestionSetSelection){
        this.$router.push({name:'editQuestionSet',params: {'questionSetId':this.QuestionSetSelection}})
      }
      setTimeout(() => {
        self.editing = JSON.parse(clone)
      }, 200);
    }
  }
}
</script>