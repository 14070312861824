<template>
  <div>
    <v-btn @click="download">Download</v-btn>

    <div v-if="allow" style="overflow: scroll;">
        <table v-if="response" id="source">
            <thead>
                <tr>
                    <td>id</td>
                    <td>tool</td>
                    <td style="text-align: left;">Origin</td>
                    <td>From Link</td>
                    
                    <td>Timestamp</td>
                    <td>Treatment</td>

                    <td v-for="province in response.provinces" :key="`province-${province}`">
                        {{ province }}
                    </td>

                    <template v-for="question in questionSet.questions">
                        <td v-if="question.id!=null" :key="`question-${question.id}`" class="question-body">
                            {{ question.body.text[language] }}
                        </td>
    
                        <template v-for="group in question.groups">
                            <td v-if="group.text[language]" :key="`question-${question.id}-group-${group.id}`" class="question-group">
                                {{ group.text[language] }}
                            </td>
    
                            <td v-for="choice in group.choices" :key="`question-${question.id}-group-${group.id}-choice-${choice.id}`" class="question-choice">
                                {{ choice.text[language] }}
                            </td>
    
                        </template>
                    </template>
                </tr>
            </thead>
    
            <tbody>
                <tr v-for="(record) in response.results" :key="`record-${record.id}`">
                    <td>
                        {{ record.id }}
                    </td>
                    <td>
                        {{ $route.query.toolId }}
                    </td>
                    <td style="text-align: left;">
                        {{ record.origins.split('?')[0] }}
                    </td>
                    <td>
                        {{record.from_link == '1' ? '1' : ''}}
                    </td>
                    <td>
                        {{ makeDate(record.timestamp) }}
                    </td>

                    <td>
                        {{ record.data.treatments.toString() }}
                    </td>

                    <td v-for="province in response.provinces" :key="`province-${province}`">
                        {{ record.data.provinces.includes(province) ? '1' : '' }}
                    </td>
    
                    <template v-for="question in questionSet.questions">
                        <td v-if="question.id!=null" :key="`${record.id}-question-${question.id}`"/>
    
                        <template v-for="group in question.groups">
                            <td v-if="group.text[language]" :key="`${record.id}-question-${question.id}-group-${group.id}`"/>
    
                            <td v-for="choice in group.choices" :key="`${record.id}-question-${question.id}-group-${group.id}-choice-${choice.id}`">
                                <template v-if="group.type=='radio'">
                                    {{ record.data.answers[question.id][group.id].value == choice.value ? '1' : '' }}
                                </template>
                                
                                <template v-else>
                                    {{ record.data.answers[question.id][group.id].value.includes(choice.value) ? '1' : '' }}
                                </template>
                            </td>
    
                        </template>
                    </template>
                </tr>
            </tbody>
        </table>

        <center v-else>
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
        </center>
    </div>


    <Blocker v-model="allow"/>    
  </div>
</template>

<script>
import exportToExcel from '@/plugins/exportToExcel.js'
import questionSet from '@/lib/questionSets/bc.json'
import Blocker from '@/components/Blocker'
export default {
    components: {
        Blocker
    },
    props: {
        Controller: {
            type: Object,
            required: true
        }
    },
    created(){
        if(this.allow){
            this.init()
        }
    },
    data(){
        return {
            config: {
                api: process.env.VUE_APP_API
            },
            response: null,
            questionSet,
            allow: false
        }
    },
    methods: {
        async init(){
            let response = await this.sendRequest('get',`${this.config.api}/getData/bc`)
            console.log(response)
            this.response = response.data
        },
        download(){
            let table = document.getElementById('source')
            exportToExcel.methods.exportTableToCSV('#source','data.csv')
            console.log('download',{table, exportToExcel})
        },
        makeDate(timestamp){
            if(timestamp){
                const options = {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                };
                let t = new Date(timestamp)
                let output = t.toLocaleDateString(undefined, options)
                output += ' '+t.toLocaleTimeString('en-US')
                return output
            }
            return '---'
        }
    },
    computed:{
        language(){
            return this.Controller.Language.language
        }
    },
    watch: {
        allow:{
            deep: false,
            handler(after, before){
                if(after===true){
                    this.init()
                }
            }
        }
    }
}
</script>

<style scoped>
thead td{
    border-bottom: 1px solid gray;
    border-right: 1px solid lightgray;
}
tbody td{
    min-width: 50px;
    text-align: center;
    border-right: 1px;
    border-right-style: solid;
    border-bottom: 1px;
    border-bottom-style: solid;
    border-color: lightgray;
}

tbody td{
    font-size: 0.7em;
}

.question-body{
    font-size: 1em;
    min-width: 200px;
}
.question-group{
    font-size: 0.8em;
}
.question-choice{
    font-size: 0.8em;
}
</style>